import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _29d3dd04 = () => interopDefault(import('../pages/aboutus.vue' /* webpackChunkName: "pages/aboutus" */))
const _09bc0716 = () => interopDefault(import('../pages/cipp-offer/index.vue' /* webpackChunkName: "pages/cipp-offer/index" */))
const _32e0cc84 = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _a705b976 = () => interopDefault(import('../pages/verify-email.vue' /* webpackChunkName: "pages/verify-email" */))
const _0366dab4 = () => interopDefault(import('../pages/business/analytics.vue' /* webpackChunkName: "pages/business/analytics" */))
const _42daa7f8 = () => interopDefault(import('../pages/business/communication.vue' /* webpackChunkName: "pages/business/communication" */))
const _090068a3 = () => interopDefault(import('../pages/business/membersupport.vue' /* webpackChunkName: "pages/business/membersupport" */))
const _6cf2ed17 = () => interopDefault(import('../pages/dashboard/combine.vue' /* webpackChunkName: "pages/dashboard/combine" */))
const _385bae10 = () => interopDefault(import('../pages/dashboard/documents.vue' /* webpackChunkName: "pages/dashboard/documents" */))
const _9dc80422 = () => interopDefault(import('../pages/dashboard/landing.vue' /* webpackChunkName: "pages/dashboard/landing" */))
const _2be3756c = () => interopDefault(import('../pages/dashboard/nextsteps.vue' /* webpackChunkName: "pages/dashboard/nextsteps" */))
const _ffdc7de8 = () => interopDefault(import('../pages/dashboard/report.vue' /* webpackChunkName: "pages/dashboard/report" */))
const _f45bfcd2 = () => interopDefault(import('../pages/dashboard/reports.vue' /* webpackChunkName: "pages/dashboard/reports" */))
const _b91afd5e = () => interopDefault(import('../pages/dashboard/retirement.vue' /* webpackChunkName: "pages/dashboard/retirement" */))
const _061b8e56 = () => interopDefault(import('../pages/dashboard/review-choices.vue' /* webpackChunkName: "pages/dashboard/review-choices" */))
const _436e5718 = () => interopDefault(import('../pages/dashboard/selected-funds.vue' /* webpackChunkName: "pages/dashboard/selected-funds" */))
const _37406850 = () => interopDefault(import('../pages/dashboard/selfhelpdirectory.vue' /* webpackChunkName: "pages/dashboard/selfhelpdirectory" */))
const _05056f0a = () => interopDefault(import('../pages/dashboard/settings.vue' /* webpackChunkName: "pages/dashboard/settings" */))
const _835fb09a = () => interopDefault(import('../pages/dashboard/shortlist-funds.vue' /* webpackChunkName: "pages/dashboard/shortlist-funds" */))
const _07d95b14 = () => interopDefault(import('../pages/onboarding/annuity-options.vue' /* webpackChunkName: "pages/onboarding/annuity-options" */))
const _ad5098fc = () => interopDefault(import('../pages/onboarding/authorization.vue' /* webpackChunkName: "pages/onboarding/authorization" */))
const _258a5566 = () => interopDefault(import('../pages/onboarding/checkout.vue' /* webpackChunkName: "pages/onboarding/checkout" */))
const _0cd625b8 = () => interopDefault(import('../pages/onboarding/employment-info.vue' /* webpackChunkName: "pages/onboarding/employment-info" */))
const _4ea7ee4b = () => interopDefault(import('../pages/onboarding/fund-selection.vue' /* webpackChunkName: "pages/onboarding/fund-selection" */))
const _5a7b4b72 = () => interopDefault(import('../pages/onboarding/login.vue' /* webpackChunkName: "pages/onboarding/login" */))
const _79c59f26 = () => interopDefault(import('../pages/onboarding/login-error.vue' /* webpackChunkName: "pages/onboarding/login-error" */))
const _4e8f9a1c = () => interopDefault(import('../pages/onboarding/money-purchase-annual-allowance.vue' /* webpackChunkName: "pages/onboarding/money-purchase-annual-allowance" */))
const _1ba38211 = () => interopDefault(import('../pages/onboarding/my-tax-liability.vue' /* webpackChunkName: "pages/onboarding/my-tax-liability" */))
const _364226d8 = () => interopDefault(import('../pages/onboarding/other-pot-addition.vue' /* webpackChunkName: "pages/onboarding/other-pot-addition" */))
const _5ac6288c = () => interopDefault(import('../pages/onboarding/pension-details.vue' /* webpackChunkName: "pages/onboarding/pension-details" */))
const _10bbc20b = () => interopDefault(import('../pages/onboarding/pension-provider-by-name.vue' /* webpackChunkName: "pages/onboarding/pension-provider-by-name" */))
const _aadfe0fa = () => interopDefault(import('../pages/onboarding/pension-savings.vue' /* webpackChunkName: "pages/onboarding/pension-savings" */))
const _e4a70bb8 = () => interopDefault(import('../pages/onboarding/personal-info.vue' /* webpackChunkName: "pages/onboarding/personal-info" */))
const _76ebd678 = () => interopDefault(import('../pages/onboarding/redeem-voucher.vue' /* webpackChunkName: "pages/onboarding/redeem-voucher" */))
const _9c0ebec0 = () => interopDefault(import('../pages/onboarding/reset-password.vue' /* webpackChunkName: "pages/onboarding/reset-password" */))
const _4dd306cc = () => interopDefault(import('../pages/onboarding/success.vue' /* webpackChunkName: "pages/onboarding/success" */))
const _1a58a82d = () => interopDefault(import('../pages/onboarding/suez-info.vue' /* webpackChunkName: "pages/onboarding/suez-info" */))
const _6c2523fa = () => interopDefault(import('../pages/onboarding/tapered-allowance.vue' /* webpackChunkName: "pages/onboarding/tapered-allowance" */))
const _2b96df61 = () => interopDefault(import('../pages/onboarding/tax-charge-period.vue' /* webpackChunkName: "pages/onboarding/tax-charge-period" */))
const _b80a11dc = () => interopDefault(import('../pages/onboarding/tax-payment-methods.vue' /* webpackChunkName: "pages/onboarding/tax-payment-methods" */))
const _c9769c4a = () => interopDefault(import('../pages/personal/account.vue' /* webpackChunkName: "pages/personal/account" */))
const _ba9b127c = () => interopDefault(import('../pages/personal/nextsteps.vue' /* webpackChunkName: "pages/personal/nextsteps" */))
const _0683aabb = () => interopDefault(import('../pages/personal/retirement.vue' /* webpackChunkName: "pages/personal/retirement" */))
const _f9c858cc = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _708d8ea5 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aboutus",
    component: _29d3dd04,
    name: "aboutus"
  }, {
    path: "/cipp-offer",
    component: _09bc0716,
    name: "cipp-offer"
  }, {
    path: "/help",
    component: _32e0cc84,
    name: "help"
  }, {
    path: "/verify-email",
    component: _a705b976,
    name: "verify-email"
  }, {
    path: "/business/analytics",
    component: _0366dab4,
    name: "business-analytics"
  }, {
    path: "/business/communication",
    component: _42daa7f8,
    name: "business-communication"
  }, {
    path: "/business/membersupport",
    component: _090068a3,
    name: "business-membersupport"
  }, {
    path: "/dashboard/combine",
    component: _6cf2ed17,
    name: "dashboard-combine"
  }, {
    path: "/dashboard/documents",
    component: _385bae10,
    name: "dashboard-documents"
  }, {
    path: "/dashboard/landing",
    component: _9dc80422,
    name: "dashboard-landing"
  }, {
    path: "/dashboard/nextsteps",
    component: _2be3756c,
    name: "dashboard-nextsteps"
  }, {
    path: "/dashboard/report",
    component: _ffdc7de8,
    name: "dashboard-report"
  }, {
    path: "/dashboard/reports",
    component: _f45bfcd2,
    name: "dashboard-reports"
  }, {
    path: "/dashboard/retirement",
    component: _b91afd5e,
    name: "dashboard-retirement"
  }, {
    path: "/dashboard/review-choices",
    component: _061b8e56,
    name: "dashboard-review-choices"
  }, {
    path: "/dashboard/selected-funds",
    component: _436e5718,
    name: "dashboard-selected-funds"
  }, {
    path: "/dashboard/selfhelpdirectory",
    component: _37406850,
    name: "dashboard-selfhelpdirectory"
  }, {
    path: "/dashboard/settings",
    component: _05056f0a,
    name: "dashboard-settings"
  }, {
    path: "/dashboard/shortlist-funds",
    component: _835fb09a,
    name: "dashboard-shortlist-funds"
  }, {
    path: "/onboarding/annuity-options",
    component: _07d95b14,
    name: "onboarding-annuity-options"
  }, {
    path: "/onboarding/authorization",
    component: _ad5098fc,
    name: "onboarding-authorization"
  }, {
    path: "/onboarding/checkout",
    component: _258a5566,
    name: "onboarding-checkout"
  }, {
    path: "/onboarding/employment-info",
    component: _0cd625b8,
    name: "onboarding-employment-info"
  }, {
    path: "/onboarding/fund-selection",
    component: _4ea7ee4b,
    name: "onboarding-fund-selection"
  }, {
    path: "/onboarding/login",
    component: _5a7b4b72,
    name: "onboarding-login"
  }, {
    path: "/onboarding/login-error",
    component: _79c59f26,
    name: "onboarding-login-error"
  }, {
    path: "/onboarding/money-purchase-annual-allowance",
    component: _4e8f9a1c,
    name: "onboarding-money-purchase-annual-allowance"
  }, {
    path: "/onboarding/my-tax-liability",
    component: _1ba38211,
    name: "onboarding-my-tax-liability"
  }, {
    path: "/onboarding/other-pot-addition",
    component: _364226d8,
    name: "onboarding-other-pot-addition"
  }, {
    path: "/onboarding/pension-details",
    component: _5ac6288c,
    name: "onboarding-pension-details"
  }, {
    path: "/onboarding/pension-provider-by-name",
    component: _10bbc20b,
    name: "onboarding-pension-provider-by-name"
  }, {
    path: "/onboarding/pension-savings",
    component: _aadfe0fa,
    name: "onboarding-pension-savings"
  }, {
    path: "/onboarding/personal-info",
    component: _e4a70bb8,
    name: "onboarding-personal-info"
  }, {
    path: "/onboarding/redeem-voucher",
    component: _76ebd678,
    name: "onboarding-redeem-voucher"
  }, {
    path: "/onboarding/reset-password",
    component: _9c0ebec0,
    name: "onboarding-reset-password"
  }, {
    path: "/onboarding/success",
    component: _4dd306cc,
    name: "onboarding-success"
  }, {
    path: "/onboarding/suez-info",
    component: _1a58a82d,
    name: "onboarding-suez-info"
  }, {
    path: "/onboarding/tapered-allowance",
    component: _6c2523fa,
    name: "onboarding-tapered-allowance"
  }, {
    path: "/onboarding/tax-charge-period",
    component: _2b96df61,
    name: "onboarding-tax-charge-period"
  }, {
    path: "/onboarding/tax-payment-methods",
    component: _b80a11dc,
    name: "onboarding-tax-payment-methods"
  }, {
    path: "/personal/account",
    component: _c9769c4a,
    name: "personal-account"
  }, {
    path: "/personal/nextsteps",
    component: _ba9b127c,
    name: "personal-nextsteps"
  }, {
    path: "/personal/retirement",
    component: _0683aabb,
    name: "personal-retirement"
  }, {
    path: "/blog/:slug?",
    component: _f9c858cc,
    name: "blog-slug"
  }, {
    path: "/",
    component: _708d8ea5,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
